<template>
  <component :is="props.tag" class="cursor-pointer" @click="navigateToAnotherPageIfMaskingIsEnabled" @keydown.enter="navigateToAnotherPageIfMaskingIsEnabled">
    <!-- Masking enabled -->
    <template v-if="!props.maskingDisabled">
      <slot />
    </template>
    <component
      :is="unmaskedLinkIs"
      v-if="props.maskingDisabled && !props.newTab"
      :class="props.maskingDisabledCss"
      target="_self"
      :aria-label="props.maskingAriaLabel"
      tabindex="0"
      role="link"
      v-bind="{
        ...(props.to ? { to: props.to } : {}),
        ...(!props.to && props.href ? { href: props.href } : {}),
      }"
    >
      <slot />
    </component>
    <component
      :is="unmaskedLinkIs"
      v-if="props.maskingDisabled && props.newTab"
      :class="props.maskingDisabledCss"
      target="_blank"
      :aria-label="props.maskingAriaLabel"
      tabindex="0"
      role="link"
      v-bind="{
        ...(props.to ? { to: props.to } : {}),
        ...(!props.to && props.href ? { href: props.href } : {}),
      }"
    >
      <slot />
    </component>
  </component>
</template>

<script setup>
const props = defineProps({
  to: {
    type: String,
    default: null
  },
  href: {
    type: String,
    default: null
  },
  external: {
    type: Boolean,
    default: false
  },
  newTab: {
    type: Boolean,
    default: true
  },
  tag: {
    type: String,
    default: 'div'
  },
  maskingDisabled: {
    type: Boolean,
    default: false
  },
  maskingDisabledCss: {
    type: String,
    default: null
  },
  maskingAriaLabel: {
    type: [String, Array, Number],
    default: ''
  }
})

const unmaskedLinkIs = computed(() => props.to ? resolveComponent('NuxtLink') : (props.href ? 'a' : props.tag))

async function navigateToAnotherPageIfMaskingIsEnabled () {
  if (props.maskingDisabled) {
    return
  }

  // Nuxt link (prefetching etc.)
  if (props.to) {
    const openOption = props.newTab ? { open: { target: '_blank' } } : {}
    await navigateTo(
      props.to,
      {
        redirectCode: 302,
        external: props.external || props.newTab,
        ...openOption
        // open: {
        //   target: props.newTab ? '_blank' : '_self'
        // }
      }
    )
    return
  }

  // Normal link (No prefetching)
  if (!props.to && props.href) {
    window.open(props.href, props.newTab ? '_blank' : '_self')
  }
}
</script>

<style scoped>
</style>
